import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'
import { colors } from '../../../../../utils/styles/styles'

const LearnMoreLinkWrapper = styled.div`
  margin-top: 0.5rem;
  a {
    cursor: pointer;
    color: ${colors.pri};
    text-decoration: underline;
    font-size: 1.6rem;
    &:hover {
      color: ${colors.sec};
    }
  }
`

const LearnMoreLink = (props) => {
  return (
    <LearnMoreLinkWrapper>
      <Link
        to={`/features/${props.page}`}
      >
        Learn More!
      </Link>
    </LearnMoreLinkWrapper>
  )
}

export default LearnMoreLink

import styled from 'styled-components'
import { colors } from '../../../../../utils/styles/styles'

const TextContainer = styled.div`
  flex-basis: 40%;
  max-width: 400px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h3 {
    margin-bottom: 4px; 
    font-size: 30px;
  }
  span {
    margin: 10px 0px 5px;
    color: ${props => props.theme.bodyColor};
    font-weight: 300;
  }
  div.container__questions {
    ul {
      li {
        color: ${props => props.theme.bodyColor};
        font-weight: 300;
      }
    }
  }
  @media (max-width: 768px) {
    flex-basis: 100%;
    max-width: none;
    padding: 20px 0px;
    align-items: center;
    h3 {
      margin-bottom: 0px;
      font-size: 26px;
      text-align: left;
    }
    span {
      margin: 4px 0px;
      text-align: left;
    }
    > * {
      width: 100%;
      max-width: 600px;
    }
  }
`

export default TextContainer
import React, { Component } from 'react'
import Layout from "../components/resuable/layout/layout";
import Hero from '../components/resuable/hero/hero';
import Joinus from '../components/resuable/joinus/joinus';
import FeaturesMainSection from '../components/page/feature-components/features-main/features-main-section';
import SEO from '../components/resuable/seo/seo';
import FeaturesHero from '../components/page/feature-components/features-main/features-hero/features-hero';
import FeaturesBody from '../components/page/feature-components/features-main/features-body/features-body';

export class Features extends Component {
  render() {
    return (
      <Layout>
        <SEO 
          title="Leadmirror Features"
        />
        <FeaturesHero />
        <FeaturesBody />
        <Joinus />
      </Layout>
    )
  }
}

export default Features

import React from 'react'
import styled from 'styled-components'
import Hero from '../../../../resuable/hero/hero'

const StyledFeaturesHero = styled.div`
  .featurehero {
    margin-bottom: 0px;
    clip-path: none;
  }
`

const FeaturesHero = (props) => {
  return (
    <StyledFeaturesHero>
      <Hero 
        title="Empowering the way of digital marketing"
        className="featurehero"
      />
    </StyledFeaturesHero>
  )
}

FeaturesHero.defaultProps = {

}

export default FeaturesHero
import styled from 'styled-components'

const ImageContainer = styled.div`
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  img {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
  video {
    width: 100%;
    margin: 0px;
    padding: 0px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    img {
      /* flex-basis: 100%;
      max-width: 768px; */
      width: 100%;
    }
  }
`

export default ImageContainer
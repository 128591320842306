import React from 'react'
import styled from 'styled-components'
import { FeatureSection } from '../../../home-components/home-features-section'

const StyledFeaturesBody = styled.div`
  
`

const FeaturesBody = (props) => {
  return (
    <StyledFeaturesBody>
      <FeatureSection.TabsHighlight />
    </StyledFeaturesBody>
  )
}

FeaturesBody.defaultProps = {

}

export default FeaturesBody
import React from 'react'
import styled from 'styled-components'
import HomeFeaturesSection from '../../home-components/home-features-section/home-features-section'

const StyledFeaturesMainSection = styled.div`
  div.container__features {
    background: red;
  }
`

const FeaturesMainSection = (props) => {
  return (
    <StyledFeaturesMainSection>
      <HomeFeaturesSection className="container__features"/>
    </StyledFeaturesMainSection>
  )
}

FeaturesMainSection.defaultProps = {

}

export default FeaturesMainSection
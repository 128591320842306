import styled from 'styled-components'

const HomeFeaturesSectionRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.reverseOrder ? 'row' : 'row-reverse' };
  justify-content: flex-start;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 20rem;
  }
  @media (max-width: 768px) {
    box-sizing: border-box;
    margin: 4rem 0px;
    width: 100%;
    margin: 0px 0px 20px;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 8rem;
    }
    &:not(:last-child) {
      margin-bottom: 8rem;
    }
  }
`

HomeFeaturesSectionRow.defaultProps = {
  reverseOrder: false
}

export default HomeFeaturesSectionRow
import React, { Component, useContext } from "react"

import styled, { ThemeContext } from "styled-components"

/* The following are helper components */
import Section from "../../../resuable/section/section"
import LearnMoreLink from "./learn-more-link/learn-more-link"
import ImageContainer from "./image-container/image-container"
import TextContainer from "./text-container/text-container"
import HomeFeaturesSectionRow from "./home-features-section-row/home-features-section-row"

import OrganicSense from "../../../../videos/OrganicSense.mp4"
import InPage from "../../../../videos/InPage.mp4"
import LinkSense from "../../../../videos/LinkSense.mp4"

import OrganicSensePoster from "../../../../videos/OrganicSense.jpg"
import InPagePoster from "../../../../videos/InPage.jpg"
import LinkSensePoster from "../../../../videos/LinkSense.jpg"

import Tiles from "../../../../images/patterns/papyrus-dark/papyrus-dark.png"
import TilesWhite from "../../../../images/patterns/papyrus/papyrus.png"

import { dimensions, colors } from "../../../../utils/styles/styles"
import { ButtonType } from "../../../resuable/button"
import { Link } from "gatsby"
import { getSourceLink } from "../../../../utils/tracker/links"

const HomeFeaturesSectionContainer = styled.div`
  width: 100%;
  padding: 3rem 0 6rem;
`

const HomeFeaturesSectionTop = styled.div`
  width: 100%;
  margin-bottom: 6rem;
  padding: 10rem 2rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${props =>
      props.theme.name === "dark" ? Tiles : TilesWhite})
    repeat;
  h3 {
    margin-bottom: 1.2rem;
    text-align: center;
    font-size: 4.8rem;
    span.pulse {
      font: inherit;
      animation: 2s pulse infinite forwards;
    }
  }
  span {
    max-width: ${dimensions.paraWidth};
    display: inline-block;
    color: ${colors.gray};
    text-align: center;
  }
  button {
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    margin-bottom: 0rem;
    padding: 6rem 2rem 6rem;
    h3 {
      font-size: 2.8rem;
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`

const HomeFeaturesSection = () => {
  const theme = useContext(ThemeContext)
  return (
    <Section
      height="auto"
      padding="0px"
      fullwidth={true}
      justifyContent="center"
    >
      <HomeFeaturesSectionContainer>
        <HomeFeaturesSectionTop>
          <h3>
            With <span className="pulse">❤️</span> from Developers, to the best
            Marketers
          </h3>
          <span>
            Escape the data clutter. Do-It-Yourself Marketing that harnesses the
            insights that are relevant to your brand success.
          </span>
          <a
            href={`https://app.leadmirror.com/#/signup/wizard/1?initLandingPage=${getSourceLink()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonType.Ghost id="home-best_dev">
              Start Now For Free!
            </ButtonType.Ghost>
          </a>
        </HomeFeaturesSectionTop>

        {/* 1. OSP */}
        <HomeFeaturesSectionRow>
          <ImageContainer id="osp">
            <video
              autoPlay={true}
              loop={true}
              muted={true}
              poster={OrganicSensePoster}
              playsinline={true}
            >
              <source src={OrganicSense} />
            </video>
          </ImageContainer>
          <TextContainer theme={theme}>
            <h3>Stop assuming your visibility and watch reality in action.</h3>
            <span>
              Hub for understanding the direction of you and your competitors’
              marketing activities. OSP gets into action every day to help you
              answer the following:
            </span>
            <div className="container__questions">
              <ul>
                <li>Where do I rank?</li>
                <li>Who am I competing with?</li>
                <li>What are my users searching for?</li>
              </ul>
            </div>
            <LearnMoreLink page="organic-sense" />
          </TextContainer>
        </HomeFeaturesSectionRow>

        {/* 2. In Page */}
        <HomeFeaturesSectionRow reverseOrder>
          <ImageContainer>
            <video
              autoPlay={true}
              loop={true}
              muted={true}
              poster={InPagePoster}
              playsinline={true}
            >
              <source src={InPage} />
            </video>
          </ImageContainer>
          <TextContainer theme={theme}>
            <h3>
              Structure your SEO meta by inspecting multiple page elements
            </h3>
            <span>
              A mirror of your structural SEO. In-Page allows you to reverse
              engineer the entire SEO strategy of any given webpage by providing
              answers for following data on a daily basis.
            </span>
            <div className="container__questions">
              <ul>
                <li>Do crawlers love your page as much as you do?</li>
                <li>What is your competition up to?</li>
                <li>How are market leaders thinking?</li>
              </ul>
            </div>
            <LearnMoreLink page="in-page" />
          </TextContainer>
        </HomeFeaturesSectionRow>

        {/* 3. LinkSense */}
        <HomeFeaturesSectionRow>
          <ImageContainer>
            <video
              autoPlay={true}
              loop={true}
              muted={true}
              poster={LinkSensePoster}
              playsinline={true}
            >
              <source src={LinkSense} />
            </video>
          </ImageContainer>
          <TextContainer theme={theme}>
            <h3>Stronger the links, better the authority.</h3>
            <span>
              LinkSense is built as the most efficient link building mechanism.
              It is astonishingly simple as you enter a URL and within minutes,
              the entire link building strategy of the page along with the
              following metrics will be available for analysis.
            </span>
            <div className="container__questions">
              <ul>
                <li>Check out the best and the worst referrers</li>
                <li>Identify the spam and eliminate it</li>
                <li>Witness what’s working out for the forerunners</li>
              </ul>
            </div>
            <LearnMoreLink page="link-sense" />
          </TextContainer>
        </HomeFeaturesSectionRow>
      </HomeFeaturesSectionContainer>
    </Section>
  )
}

export default HomeFeaturesSection
